<template>
  <el-dialog title="下载报告" :visible.sync="dialogVisible" width="600px">
    <!-- <diaog-search v-if="dialogVisible"></diaog-search> -->
    <el-button type="text" disabled>请选择报告</el-button>

    <el-checkbox-group v-model="checkboxGroup">
      <div v-for="(item, i) in list" :key="i" style="position: relative">
        <el-link
          style="
            position: absolute;
            right: 15px;
            top: 20px;
            font-size: 12px;
            z-index: 10;
          "
          type="info"
          @click.stop="show(item.name)"
          >预览 ></el-link
        >
        <el-checkbox style="width: 100%" :label="item.name" border>
        </el-checkbox>
      </div>
    </el-checkbox-group>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="down(2)" :loading="loading2"
        >下载word</el-button
      >
      <el-button type="primary" @click="down(0)" :loading="loading0"
        >下载pdf</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as sesstion from "../../store/localStorage";
import { checkpoint, logActivity, tjINdustryList } from "@/api";
let loadingFlag = [];
export default {
  components: {
    // DiaogSearch: require("@/components/publicSearch/DiaogSearch.vue").default,
  },

  data() {
    return {
      is_superuser: sesstion.getLocalData("userData").is_superuser,
      loading0: false,
      loading2: false,
      dialogVisible: false,
      checkboxGroup: [],
      list: [],
    };
  },
  created() {
    window.dialog = this;
    if (this.getUserRole("quality_report")) {
      this.list.push({
        name: "报表质量分析报告",
        time: 20000,
      });
    }
    if (this.getUserRole("industry_report")) {
      this.list.push({
        name: "行业比较分析报告",
        time: 20000,
      });
    }
    if (this.getUserRole("basic_report")) {
      this.list.push({
        name: "专用信贷版分析报告",
        time: 20000,
      });
    }
    if (this.getUserRole("simple_report")) {
      this.list.push({
        name: "一般企业信贷分析报告",
        time: 20000,
      });
    }
    if (this.getUserRole("main_report")) {
      this.list.push({
        name: "财务分析主报告",
        time: 20000,
      });
    }
  },
  methods: {
    async down(status = 0) {
      if (this.checkboxGroup.length == 0)
        return this.$message.error("请先勾选要下载的报告");

      this[`loading${status}`] = true;
      loadingFlag = [];
      const company = sesstion.getLocalData("company");
      const companyData = sesstion.getLocalData("companyData");
      const senddata = {
        Stock_code: companyData.stock_code || "",
        stock_code: companyData.stock_code || "",
        year: companyData.year,
        report_time: companyData.report_time,
        report_type: companyData.report_type,
        company_name: company.S_or_B_name || company.name,
        company_code: company.company_code,
        generalEvaluation: this.$VM.CompanyDetail.generalEvaluation,
        industry_code: companyData.industry_code,
        industry_name: companyData.industry_name,
        company_code: company.company_code,
        coefficient: 0.5,
      };

      let param = {
        industry_number: senddata.industry_code,
        industry_range: 0,
        menu_id: 7,
        parsing_time: senddata.year,
        report_time: senddata.report_time,
        report_type: senddata.report_type,
        industry_type: senddata.industry_name,
        company_code: senddata.company_code,
      };
      checkpoint(param).then(({ data }) => {
        if (data.Status != "1") {
          this.loading0 = false;
          this.loading2 = false;
          return this.$message.warning(data.Message);
        } else {
          logActivity(param);
          sessionStorage.downWord = JSON.stringify(senddata);
          var iframe = "";
          this.checkboxGroup.map((name) => {
            const { href } = this.$router.resolve({
              name: `downWord`,
              query: {
                checkboxGroup: [name],
                status: status,
              },
            });

            iframe += `<iframe style="position: fixed;left: 1px" class="iframe"  src="${href}"></iframe>`;
            loadingFlag.push({ name: name, status: true });
          });

          $("body").append(iframe);
        }
      });
    },
    closeLoading(name) {
      for (let i = 0; i < loadingFlag.length; i++) {
        const item = loadingFlag[i];
        if (item.name == name) item.status = false;
      }
      const statusBox = loadingFlag.map((item) => item.status);
      if (statusBox.includes(true)) return false;
      this.loading0 = false;
      this.loading2 = false;
    },

    async getIndustryData(category) {
      let dataParam = "";
      let CSRC_code = hideSelectTree.downgb.industry_code;
      let statistics_code = hideSelectTree.downtj.industry_code;
      let industry_code = hideSelectTree.downsw.industry_code;
      let res = await tjINdustryList({
        CSRC_code: CSRC_code,
        industry_code: industry_code,
        statistics_code: statistics_code,
      });
      let Result = "";
      if (category == "gb") {
        Result = res.data.gbResult;
      } else if (category == "tj") {
        Result = res.data.tjResult;
      } else if (category == "sw") {
        Result = res.data.swResult;
      }
      dataParam = {
        Industry_five:
          (Result["industry_level_five"] &&
            Result["industry_level_five"].industry_name) ||
          "",
        Industry_four:
          (Result["industry_level_four"] &&
            Result["industry_level_four"].industry_name) ||
          "",
        Industry_one:
          (Result["industry_level_one"] &&
            Result["industry_level_one"].industry_name) ||
          "",
        Industry_three:
          (Result["industry_level_three"] &&
            Result["industry_level_three"].industry_name) ||
          "",
        Industry_two:
          (Result["industry_level_two"] &&
            Result["industry_level_two"].industry_name) ||
          "",
        Industry_type: category === "gb" ? "tj" : category,
      };
      return dataParam;
    },
    async show(name) {
      const { href } = this.$router.resolve({
        name: `downWord`,
        query: {
          checkboxGroup: name,
          status: 1,
        },
      });
      const companyData = sesstion.getLocalData("companyData");
      const senddata = {
        Stock_code: companyData.stock_code || "",
        year: companyData.year,
        report_time: companyData.report_time,
        report_type: companyData.report_type,
        company_name: companyData.name,
        company_code: companyData.company_code,
        generalEvaluation: this.$VM.CompanyDetail.generalEvaluation,
        industry_code: companyData.industry_code,
        industry_name: companyData.industry_name,
        coefficient: 0.5,
      };
      let param = {
        industry_number: senddata.industry_code,
        industry_range: 0,
        menu_id: 7,
        parsing_time: senddata.year,
        report_time: senddata.report_time,
        report_type: senddata.report_type,
        industry_type: senddata.industry_name,
        company_code: senddata.company_code,
      };
      checkpoint(param).then(({ data }) => {
        if (data.Status != "1") {
          return this.$message.warning(data.Message);
        } else {
          logActivity(param);
          sessionStorage.downWord = JSON.stringify(senddata);
          window.open(href);
          return false;
        }
      });
    },
  },
};
</script>
