<template>
  <div
    id
    style="min-height: 100%; background: rgba(247, 247, 247, 1)"
    class="main-top"
  >
    <el-container style="height: 100%">
      <el-aside width="180px">
        <el-menu
          :default-active="this.$route.path"
          unique-opened
          @open="handleOpen"
        >
          <el-submenu
            index="/CompanyDetail/FinancialReport"
            v-if="getUserRole('financial_portrait')"
          >
            <template slot="title"
              ><i class="el-icon-copy-document"></i>财务画像</template
            >
            <el-menu-item
              v-for="(item, index) in financial_portrait"
              :key="index"
              index="/CompanyDetail/FinancialReport"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
            >
              {{ item }}</el-menu-item
            >
          </el-submenu>
          <el-submenu
            index="/CompanyDetail/Whitewash"
            v-if="getUserRole('whitewash_analysis')"
          >
            <template slot="title"
              ><i class="el-icon-document-copy"></i>粉饰分析</template
            >
            <el-menu-item
              v-for="(item, index) in whitewash_analysis"
              :key="index"
              index="/CompanyDetail/Whitewash"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
            >
              {{ item }}
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="/CompanyDetail/FinancialAnalysis"
            v-if="getUserRole('ability_analysis')"
          >
            <template slot="title"
              ><i class="el-icon-pie-chart"></i>能力分析</template
            >
            <el-menu-item
              v-for="(item, index) in ability_analysis"
              :key="index"
              index="/CompanyDetail/FinancialAnalysis"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
              >{{ item }}</el-menu-item
            >
          </el-submenu>
          <el-submenu
            index="/CompanyDetail/cashFlow"
            v-if="getUserRole('cash_flow')"
          >
            <template slot="title"
              ><i class="el-icon-coin"></i>现金流分析</template
            >
            <el-menu-item
              v-for="(item, index) in cash_flow"
              :key="index"
              index="/CompanyDetail/cashFlow"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
              >{{ item }}</el-menu-item
            >
          </el-submenu>
          <el-submenu
            index="/CompanyDetail/StandardComponents"
            v-if="getUserRole('benchmarking')"
          >
            <template slot="title"
              ><i class="el-icon-data-line"></i>对标分析</template
            >
            <el-menu-item
              v-for="(item, index) in benchmarking"
              :key="index"
              index="/CompanyDetail/StandardComponents"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
            >
              {{ item }}</el-menu-item
            >
          </el-submenu>

          <el-submenu
            index="/CompanyDetail/RiskWarning"
            v-if="getUserRole('risk_insight')"
          >
            <template slot="title"
              ><i class="el-icon-warning-outline"></i>风险洞察</template
            >
            <el-menu-item
              v-for="(item, index) in risk_insight"
              :key="index"
              index="/CompanyDetail/RiskWarning"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
            >
              {{ item }}</el-menu-item
            >
          </el-submenu>
          <el-submenu
            disabled
            index="/CompanyDetail/CapitalCalculation"
            v-if="getUserRole('fund_calc_module')"
          >
            <template slot="title"
              ><i class="el-icon-edit-outline"></i>资金测算</template
            >
            <el-menu-item
              v-for="(item, index) in fund_calc_module"
              :key="index"
              index="/CompanyDetail/CapitalCalculation"
              :class="{ activeitem: active === index }"
              @click="scrollTo(index)"
            >
              {{ item }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main class="posA">
          <company-header
            :generalEvaluation="generalEvaluation"
            :TJ="TJ"
            :SWAI="SWAI"
            :CSRC="CSRC"
            ref="companyHeader"
          >
          </company-header>
          <div style="margin-top: 10px" class="is_fixed">
            <public-search ref="publicSearch"></public-search>
          </div>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import * as sesstion from "../../store/localStorage";
import { mapGetters, mapState, mapActions } from "vuex";
import dialog from "../dialog/dialog";
import page from "../page/page";
import FillCompanyStepTwo from "@/components/FillCompanyStepTwo";
import { tjINdustryList, yearList } from "@/api";
// import { analysis } from "@/api/FinancialAnalysis.js";
// import CompanyNav from "./CompanyNav.vue";
export default {
  components: {
    vdialog: dialog,
    pagination: page,
    FillCompanyStepTwo,

    CompanyHeader: require("./CompanyHeader.vue").default,
    // CompanyNav: require("./CompanyNav.vue").default,
    // TopAdsorption: require("@/components/TopAdsorption/index.vue").default,
    PublicSearch: require("@/components/publicSearch/index.vue").default,
  },
  data() {
    return {
      role_kind: sesstion.getLocalData("userData").role_kind,
      active: -1, // 当前激活的导航索引
      generalEvaluation: sessionStorage.generalEvaluation || "",
      showUploadDialog: false,
      searchLists: [],
      now: -1,
      searchText: "",
      hotStand: 50,
      num: 0,
      array: [],
      lessArrays: [],
      isAdd: false,
      showTs: true,
      comData: {},
      showSelect: false,
      companyInfo: {},
      S_or_B_code: "",
      S_or_B_name: "",
      name: "",
      SWAI: "",
      CSRC: "",
      TJ: "",
      hot: "",
      publish_type: "99",
      industry_list_data: {},
      indData: {},
      navList: [],

      financial_portrait: [],
      whitewash_analysis: [],

      ability_analysis: [],

      benchmarking: [],

      risk_insight: [],

      fund_calc_module: [],
      cash_flow: [],
    };
  },

  created() {
    this.getMenuList();
  },
  mounted() {
    this.$VM.CompanyDetail = this;
    this.$store.state.showNav = true;
    this.$store.state.headLogo = true;
    this.$store.state.showFooter = true;
    this.companyInfo = sesstion.getLocalData("company");
    this.companyData = sesstion.getLocalData("companyData");
    this.$refs.companyHeader.companyInfo = this.companyInfo;
    this.$refs.companyHeader.companyInfo = {
      ...this.companyInfo,
      ...(this.companyInfo.companyInfo ? this.companyInfo.companyInfo : {}),
    };

    let row = sesstion.getLocalData("rowList");
    this.setPulicSearchDataTree(row);

    if (this.companyInfo.isCustom && document.querySelector("#manage-data")) {
      document.querySelector("#manage-data").style.visibility = "visible";
    }
    if (this.companyInfo.S_or_B_code == undefined) {
      //我的企业
      this.name = this.companyInfo.name;
      // if (this.companyInfo.tj_category) {
      // this.TJ = this.companyInfo.tj_category.industry_name;
      // }
      // if (this.companyInfo.SW_category != null) {
      //   this.SWAI = this.companyInfo.SW_category.industry_name
      //
      // }

      // tjINdustryList({
      //   statistics_code: this.companyInfo.statistics_code || "",
      //   industry_code: this.companyInfo.SWAI_code || "",
      //   CSRC_code: this.companyInfo.CSRC_code || "",
      // })
      //   .then((res) => {
      //     // console.log(res);
      //     sesstion.setLocalData("industryData", res.data);
      //     this.CSRC = this.getLastLevelCategory(
      //       res.data,
      //       "tjResult"
      //     ).industry_name;
      //   })
      //   .catch((err) => {
      //     // console.error(err)
      //     // this.$tip({
      //     //   text: JSON.stringify(err)
      //     // })
      //   });

      // 非上市公司使用实际是国标

      if (this.companyInfo.CSRC_category == null) {
        // this.CSRC = this.companyInfo.companyInfo.CSRC_category.industry_name;
        tjINdustryList({
          statistics_code: this.companyInfo.statistics_code || "",
          industry_code: this.companyInfo.SWAI_code || "",
          CSRC_code: this.companyInfo.CSRC_code || "",
        })
          .then((res) => {
            console.info("tjINdustryList", res);
            sesstion.setLocalData("industryData", res.data);

            this.$VM.PublicSearch.$refs.selectTreegb.industryData = res.data;
            this.$VM.PublicSearch.$refs.selectTreegb.setGbDefualtTitle();
            // this.CSRC = this.getLastLevelCategory(
            //   res.data,
            //   "gbResult"
            // ).industry_name;
            // this.TJ = this.getLastLevelCategory(
            //   res.data,
            //   "tjResult"
            // ).industry_name;
          })
          .catch((err) => {
            // this.$tip({
            //   text: JSON.stringify(err),
            // });
          });
      }
    } else {
      //上市企业  申万
      this.S_or_B_code = this.companyInfo.S_or_B_code;
      this.S_or_B_name = this.companyInfo.S_or_B_name;
      this.name = this.companyInfo.companyInfo.name;
      if (this.companyInfo.companyInfo.SW_category != null) {
        this.SWAI = this.companyInfo.companyInfo.SW_category.industry_name;

        tjINdustryList({
          statistics_code: this.companyInfo.companyInfo.statistics_code || "", //tj
          industry_code: this.companyData.industry_code || "", //sw
          CSRC_code: this.companyInfo.companyInfo.CSRC_code || "", //gb
        })
          .then((res) => {
            console.info("tjINdustryList", res);
            sesstion.setLocalData("industryData", res.data);

            this.$VM.PublicSearch.$refs.selectTreesw.industryData = res.data;
            this.$VM.PublicSearch.$refs.selectTreesw.setSwDefualtTitle();

            // this.companyInfo.companyInfo.CSRC_category = this.getLastLevelCategory(
            //   res.data,
            //   "Result"
            // );
            // console.log(this.getLastLevelCategory(res.data, "swResult").industry_name);
            // this.SWAI = this.getLastLevelCategory(
            //   sesstion.setLocalData("industryData"),
            //   "swResult"
            // ).industry_name;
          })
          .catch((err) => {
            console.error(err);
            // this.$tip({
            //   text: JSON.stringify(err),
            // });
          });
      }

      this.hot = this.companyInfo.companyInfo.query_the_heat;
      this.publish_type = this.companyInfo.publish_type;
    }
    if (!this.$store.state.isLogin.hasLogin) {
      this.$router.push("/Sign");
    }

    this.navList = document.querySelectorAll(".test1");
    // 监听滚动事件
    document
      .querySelector(".el-main")
      .addEventListener("scroll", this.onScroll);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    document
      .querySelector(".el-main")
      .removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // 滚动监听器
    onScroll() {
      // debugger
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".test1");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop);
      });
      // 获取当前文档流的 scrollTop
      let scrollTop = document.getElementsByClassName("el-main")[0].scrollTop;
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      if (
        scrollTop +
          document.getElementsByClassName("el-main")[0].clientHeight ===
        document.getElementsByClassName("el-main")[0].scrollHeight
      ) {
        navIndex = offsetTopArr.length - 1;
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // debugger
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1

      let list = document.querySelectorAll(`.test1`);
      const targetOffsetTop = list[index].offsetTop;

      // const targetOffsetTop = document.querySelector(
      //   `.test1:nth-child(${index + 1})`
      // ).offsetTop;
      // 获取当前 offsetTop
      let scrollTop = document.getElementsByClassName("el-main")[0].scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      const STEP = 50;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.getElementsByClassName("el-main")[0].scrollTop = scrollTop;
          // document.documentElement.scrollTop = scrollTop;
          //      setTimeout(() => {
          //   document.body.scrollTop = scrollTop - 60;
          //     document.documentElement.scrollTop = scrollTop - 60;
          // }, 2000)

          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.getElementsByClassName("el-main")[0].scrollTop = scrollTop;
          // document.documentElement.scrollTop = scrollTop;
          //                setTimeout(() => {
          //   document.body.scrollTop = scrollTop - 60;
          //     document.documentElement.scrollTop = scrollTop - 60;
          // }, 2000)
          requestAnimationFrame(smoothUp);
        }
      }
    },
    handleSelect(val) {
      if (val == this.$route.path) {
      } else {
        let path = val.path;
        this.$router.push(path); //在选中方法中编写path跳转代码
        this.$VM.PublicSearch && this.$VM.PublicSearch.search();
        this.active = -1;
      }
    },

    // 菜单权限操作
    getMenuList() {
      // 财务画像
      if (this.getUserRole("overall_evaluation")) {
        this.financial_portrait.push("报表总体评价");
      }
      if (this.getUserRole("Industry_trend")) {
        this.financial_portrait.push("所属行业走势");
      }
      if (this.getUserRole("Intelligent_tool")) {
        this.financial_portrait.push("报表智能工具");
      }
      // 粉饰识别
      if (this.getUserRole("whitewash_overview")) {
        this.whitewash_analysis.push("粉饰识别概览");
      }
      if (this.getUserRole("whitewash_details")) {
        this.whitewash_analysis.push("粉饰识别明细");
      }

      // 能力分析
      if (this.getUserRole("financial_capacity")) {
        this.ability_analysis.push("财务能力概览");
      }
      if (this.getUserRole("structural_analysis")) {
        this.ability_analysis.push("结构趋势分析");
      }
      if (this.getUserRole("account_analysis")) {
        this.ability_analysis.push("科目异动分析");
      }
      if (this.getUserRole("financial_analysis")) {
        this.ability_analysis.push("财务指标分析");
      }

      // if (this.getUserRole("classic_dupont")) {
      //   this.ability_analysis.push("经典杜邦分析");
      // }
      if (this.getUserRole("industry_ranking")) {
        this.ability_analysis.push("行业排名分析");
      }

      // 现金流分析
      if (this.getUserRole("cf_overview")) {
        this.cash_flow.push("现金流概览");
      }
      if (this.getUserRole("cf_structure")) {
        this.cash_flow.push("现金流结构");
      }
      if (this.getUserRole("cf_top3")) {
        this.cash_flow.push("现金流TOP3");
      }

      // 对标分析
      if (this.getUserRole("financial_capability")) {
        this.benchmarking.push("财务能力对标");
      }
      if (this.getUserRole("structural_compositi")) {
        this.benchmarking.push("结构组成对标");
      }
      if (this.getUserRole("ranking_benchmark")) {
        this.benchmarking.push("行业排名对标");
      }
      if (this.getUserRole("risk_benchmarking")) {
        this.benchmarking.push("风险洞察对标");
      }
      // 风险洞察
      if (this.getUserRole("risk_collect")) {
        this.risk_insight.push("风险洞察汇总");
      }
      if (this.getUserRole("risk_detail")) {
        this.risk_insight.push("风险洞察明细");
      }

      // 资金测算
      if (this.getUserRole("fund_forecast")) {
        this.fund_calc_module.push("资金预测");
      }
      if (this.getUserRole("quota_calculation")) {
        this.fund_calc_module.push("额度测算");
      }
    },

    handleOpen(key, keyPath) {
      let path = keyPath[0];
      this.$router.push(path); //在选中方法中编写path跳转代码
      this.$VM.PublicSearch && this.$VM.PublicSearch.search();
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },

    // 要修改
    setPulicSearchDataTree(company) {
      let companyData = sesstion.getLocalData("companyData");
      yearList(company).then((res) => {
        this.$nextTick(() => {
          this.$refs.publicSearch.date_tree = res.data.date_tree;
          this.$refs.publicSearch.availableYears = res.data.Result;
          this.$refs.publicSearch.form_data.year = companyData.year;
          this.$refs.publicSearch.form_data.report_time =
            companyData.report_time;
          this.$refs.publicSearch.form_data.report_type =
            companyData.report_type;
          this.$refs.publicSearch.UseDefualt = true;
        });
      });
    },
    onManageData() {
      this.showUploadDialog = true;
    },
    getLastLevelCategory(data, type) {
      var keys = [];
      const categorysObj = data[type];
      let name = "";
      const types = {
        Result: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
        swResult: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
        gbResult: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
        tjResult: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
      };

      types[type].some((industry_level) => {
        if (!categorysObj[industry_level]) return false;
        name += categorysObj[industry_level].industry_name + " /";
        keys.push(industry_level);
      });

      if (name[name.length - 1] == "/") name = name.substr(0, name.length - 2);
      return {
        industry_name: name,
      };
    },
    ...mapActions([
      "queryPutData",
      "keyDownChoose",
      "keyUpChoose",
      "saveClickOrHoverIndex",
      "mouseClearStyle",
    ]),
    pagechange: function (currentPage) {
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
    },
    // queryCompany() {
    //   var value = this.searchText;
    //   if (value != "") {
    //     if (this.checkNumber(value)) {
    //       this.$http
    //         .get(
    //           window.BASE_URL +
    //           "/company/listed/search-by-stock/" +
    //           value +
    //           "/",
    //           {
    //             headers: { "X-CSRFToken": this.getCookie("csrftoken") },
    //           }
    //         )
    //         .then(
    //           (res) => {
    //             this.searchLists = res.data;
    //             // console.log(this.$store.state.customerRole);
    //           },
    //           (res) => {
    //             // console.log("请求失败！");
    //           }
    //         );
    //     } else {
    //       this.$http
    //         .get(
    //           window.BASE_URL + "/company/listed/search-by-name/" + value + "/",
    //           {
    //             headers: { "X-CSRFToken": this.getCookie("csrftoken") },
    //           }
    //         )
    //         .then(
    //           (res) => {
    //             this.searchLists = res.data;
    //           },
    //           (res) => {
    //             // console.log("请求失败！");
    //           }
    //         );
    //     }
    //   }
    // },
    // getCookie(name) {
    //   var value = "; " + document.cookie;
    //   var parts = value.split("; " + name + "=");
    // },
    // checkNumber(theObj) {
    //   let reg = /^([A-Z]|[0-9])+.?([A-Z]|[0-9])*$/;
    //   if (reg.test(theObj)) {
    //     return true;
    //   }
    //   return false;
    // },
    add(id) {
      this.isAdd = !this.isAdd;
      // console.log(this.isAdd);
    },
    ...mapGetters({
      keyDownNows: "keyDownNow",
    }),

    requestData() {
      // 在这里使用ajax或者fetch将对应页传过去获取数据即可
    },
  },
  computed: {
    ...mapState({
      checkoutSearchData: (state) => state.search.checkoutSearchData,
    }),

    list: function () {
      return this.searchLists.filter((v) => {
        return (
          v.stockCode.match(this.searchText) || v.of.match(this.searchText)
        );
      });
    },
    listLength: function () {
      return this.list.length - 1;
    },
    hots: function () {
      let f = this.hotStand;
      if (0 <= f <= 20) {
        return 1;
      } else if (20 < f <= 40) {
        return 2;
      } else if (40 < f <= 60) {
        return 3;
      } else if (60 < f <= 80) {
        return 4;
      } else {
        return 5;
      }
    },

    hotArray: function () {
      this.array.length = this.hots;
      return this.array;
    },
    lessArray: function () {
      this.lessArrays.length = 5 - this.hots;
      return this.lessArrays;
    },
    cp: function () {
      if (this.isAdd == false) {
        return "收藏";
      } else {
        return "取消对比";
      }
    },
    contrast: function () {
      return "";
    },
  },
  watch: {
    currentPage: "requestData",
    pageNo: "",
  },
  destroyed() {
    this.$store.commit("setYears", "");
    this.generalEvaluation = "";
    sessionStorage.generalEvaluation = "";
  },
  ready() {
    this.requestData();
  },
};
</script>

<style lang="less">
.posA {
  position: relative;
  .is_fixed {
    position: sticky;
    //width: 100vw;
    z-index: 999;
    top: 0px;
    background: #fff;
    height: 50px;
    padding-top: 10px;
    padding-left: 20px;
    box-shadow: 0 0 15px #bcbbbb;
  }
}
.el-submenu .el-menu {
  // background: url("../../assets/img/search/bg2.png") no-repeat center center;
  background-size: cover;
}

.is_fixed {
  position: "fixed";
  top: "0.65rem";
}

/* 导航栏的样式 */
.navs {
  // position: fixed;
  top: 80px;
  left: 700px;
  background-color: #dfe9ff;
  z-index: 100;
}

.navs li {
  padding: 0 20px;
  line-height: 1.6;
  font-size: 30px;
}

/* 当导航被点亮后改变颜色 */
.activeitem {
  color: #ffffff !important;
  background-image: linear-gradient(90deg, #3c9bfe 7%, #3a85fd 100%);
}

.el-menu {
  background: transparent;
  // background-color: #dfe9ff;
}

.el-header {
  // background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-menu-item.is-active {
  color: #fff;
}

// .aa {
//   width: 180px !important;
// }

.el-aside {
  color: #fff;
  z-index: 100;
  // background: #dfe9ff;
  background: url("../../assets/img/search/bg2.png") repeat center;
  box-shadow: 0px 2px 8px 0px rgba(44, 104, 255, 0.1);
  position: fixed;
  height: 100%;

  .el-submenu__icon-arrow {
    font-size: 18px;
  }

  .el-submenu.is-active .el-submenu__title {
    // width: 64px;
    // height: 22px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #3a85fd;
    // font-weight: 500;

    i {
      color: #3a85fd;
    }
  }

  .el-submenu .el-submenu__title {
    // width: 64px;
    // height: 22px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #fff;
    // font-weight: 500;

    i {
      color: #fff;
    }
  }
}

.el-main {
  position: absolute;
  left: 180px;
  right: 0;
  top: 50px;
  bottom: 0;
  overflow-y: scroll;
  background: url("../../assets/img/detail/main.png") no-repeat center center;
  background-size: cover;
}

.detail {
  //  border: 1px solid yellow;
  display: inline-block;
  flex: 1;
  flex-basis: auto;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 15px;
}

// .pop-up {
//   top: -50px;
//   z-index: 999;
//   background: -webkit-linear-gradient(to bottom,
//       rgba(255, 255, 253, 0.7) 1%,
//       rgba(255, 255, 255, 1) 100%);
//   /* Safari 5.1 - 6 */
//   background: -o-linear-gradient(to bottom,
//       rgba(255, 255, 253, 0.7) 5%,
//       rgba(255, 255, 255, 1) 100%);
//   /* Opera 11.1 - 12*/
//   background: -moz-linear-gradient(to bottom,
//       rgba(255, 255, 253, 0.7) 1%,
//       rgba(255, 255, 255, 1) 100%);
//   /* Firefox 3.6 - 15*/
//   background: linear-gradient(to bottom,
//       rgba(255, 255, 253, 0.7) 1%,
//       rgba(255, 255, 255, 1) 100%);
//   /* 标准的语法 */
// }

// .data-num {
//   background: -webkit-radial-gradient(#1f457f 0%, #235d97 25%, #1f457f 80%);
//   background: -o-radial-gradient(#1f457f 0%, #235d97 25%, #1f457f 80%);
//   background: -moz-radial-gradient(#1f457f 0%, #235d97 25%, #1f457f 80%);
//   background: radial-gradient(#1f457f 0%, #235d97 25%, #1f457f 80%);
//   letter-spacing: 4px;
//   vertical-align: super;
// }

#download-table,
#manage-data {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.3rem;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 15px;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  color: #1270ff;
  margin-right: 5px;

  img {
    // width: 40px;
    height: 24px;
  }

  span {
    word-break: keep-all;
  }
}

.head {
  .bg-color {
    background: rgba(243, 94, 94, 1) !important;
    border-radius: 0;
    font-size: 12px;
    width: 40px;
    height: 20px;
    color: #fff !important;
    line-height: 20px;
    padding: 0 !important;
    text-align: center;
  }
}

#download-table {
  font-size: 12px;
  border: 1px solid rgba(220, 223, 230, 1);
  opacity: 1;
  border-radius: 4px;
  color: #1270ff;

  img {
    height: 20px;
    width: 17px;
    margin-right: 5px;
  }
}

.right-btns {
  height: 100%;
  display: flex;
  align-items: center;
}

.select-midle {
  width: 160px;
  height: 28px;
  line-height: 28px;
}

.select-small {
  width: 80px;
  height: 28px;
  line-height: 28px;
}

.arrow-down-red,
.arrow-up-green {
  width: 30px;
  vertical-align: text-bottom;
}

.color-green {
  color: green;
}

.color-red {
  color: red;
}

#industry {
  visibility: hidden;
}

.edit-company-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 111;

  .content {
    background: #ffffff;
    padding: 30px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .bts {
    display: flex;
    justify-content: center;

    button {
      padding: 4px 20px;
      border-radius: 20px;
      margin: 10px;
    }

    .save {
      background: #1172fe;
      color: #fff;
    }

    .save_next {
      border: #1172fe 1px solid;
      color: #1172fe;
    }
  }
}

.pingji {
  margin-top: 32px;
  padding-left: 150px;
}

@media (max-width: 460px) {
  .content.upload {
    text-align: center;
  }

  .pingji {
    margin-top: 0;
    padding-left: 15px;
  }

  .mb_bgc {
    display: flex;
    flex-direction: column;
  }

  .mb_bgc_a {
    font-size: 13px;
    margin-right: 0.6rem !important;
  }

  .mb_down {
    margin-top: 5%;
    /* align-items: center; */
    align-self: end;
  }

  #download-table,
  #manage-data {
    margin-top: 5px;
    padding: 5px 15px;
  }
}
</style>
