小薇企不能点击
<template>
  <div id="company_header">
    <div class="containers">
      <div class="boxtitle">
        <img
          style="width: 70px; height: 70px"
          src="@/assets/img/head/wodeqiye1.svg"
        />
        <div style="margin-left: 20px; width: 60%">
          <div class="company_name">
            <span
              style="
                display: inline-block;
                max-width: 400px;
                font-size: 22px;
                color: #333;
              "
            >
              {{ companyInfo.name }}</span
            >
            <span v-if="companyInfo.publish_type == '00'">主板</span>
            <span v-if="companyInfo.publish_type == '01'">创业板</span>
            <span v-if="companyInfo.publish_type == '02'">中小板</span>
            <span v-if="companyInfo.publish_type == '03'">新三板</span>
            <span v-if="companyInfo.publish_type == '04'">债券</span>
            <span v-if="companyInfo.publish_type == '05'">科创板</span>
            <span v-if="companyInfo.publish_type == '06'">北证</span>
          </div>
          <div
            class="item"
            style="
              font-size: 16px;
              height: 40px;
              line-height: 40px;
              color: #666;
            "
          >
            <span v-show="companyInfo.S_or_B_name"
              >{{ companyInfo.S_or_B_name }}&nbsp&nbsp{{
                companyInfo.S_or_B_code
              }}&nbsp&nbsp</span
            >
            <!-- <span class="black"> 综合评价：</span><span class="black">{{ generalEvaluation || "-" }}</span>
            <Tooltip class="item" placement="top-start">
              <div slot="content">
                <p style="margin-bottom: 7px">综合评级表现有：</p>
                <p style="margin-bottom: 7px">A+：优秀</p>
                <p style="margin-bottom: 7px">A、A-：良好</p>
                <p style="margin-bottom: 7px">B、B+、B-：一般</p>
                <p style="margin-bottom: 7px">C、C+、C-：较差</p>
                <p style="margin-bottom: 7px">D、D+、D-：很差</p>
              </div>
              <i class="el-tooltip el-icon-question" aria-describedby="el-tooltip-7659" tabindex="0" style="
                  margin-top: 0px;
                  margin-left: 15px;
                  width: 14px;
                  height: 14px;
                  cursor: pointer;
                "></i>
            </Tooltip> -->
            <span style="margin-left: 10px">{{ $store.state.years }}</span>
          </div>
        </div>
        <div style="width: 35%; text-align: right">
          <div>
            <!-- v-if="$store.state.inside && (company_type == '0' || !company_type)" -->
            <el-button
              size="medium"
              type="primary"
              :loading="loadings"
              @click="xlsxDown"
              v-if="
                $store.state.inside && (company_type == '0' || !company_type)
              "
              >下载.xls</el-button
            >
            <!-- <template v-if="company_type >= '3'">
              <el-button
                size="medium"
                type="primary"
                :loading="loading0"
                @click="downWord('pdf')"
                >下载pdf报告</el-button
              >
              <el-button
                size="medium"
                type="primary"
                :loading="loading2"
                @click="downWord('docx')"
                >下载word报告</el-button
              >
            </template> -->
            <el-button
              v-if="getUserRole('download_report')"
              size="medium"
              type="primary"
              @click="downWord"
              >下载报告</el-button
            >
          </div>
        </div>
      </div>
      <!-- <div class="contentbox" v-if="flag">
        <div class="left">
          <div class="left_content">
            <div class="item">
              <span style="color:#666">成立时间：</span><span style="color:#333">{{ qcc_CompanyInfo_data.start_date || "-"
              }}</span>
            </div>
            <div v-if="company_type >= '3'">
              <div class="item row">
                <span style="color:#666">行业：</span><span style="flex: 1;color:#333">{{
                    cause_industry_code[company_type][1] || "-"
                }}</span>
              </div>
            </div>
            <div v-else>
              <div class="item row">
                <span style="color:#666">国标行业：</span><span style="flex: 1;color:#333">{{ TJ_code || "-" }}</span>
              </div>
              <div class="item row">
                <span style="color:#666">统计局行业：</span><span style="flex: 1;color:#333">{{ CSRC_code || "-" }}</span>
              </div>
              <div class="item row">
                <span style="color:#666">申万行业：</span><span style="flex: 1;color:#333">{{ SWAI_code || "-" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span style="color:#666">注册资本：</span><span style="color:#333">{{ qcc_CompanyInfo_data.registered_capital ||
                "-"
            }}</span>
          </div>
          <div class="item">
            <span style="color:#666">股权架构：</span><span class="green pointer"
              @click="check(qcc_CompanyInfo_data.data)">点击查看</span>
          </div>
          <div class="item">
            <span style="color:#666">经营范围：</span>
            <span style="color:#333">{{ qcc_CompanyInfo_data.business_scope || "-" }}</span>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="btn">
        <el-button
              v-show="flag"
              class="click"
              @click="onClick(0)"
              type="text"
              >收起 <img src="@/assets/img/arrow/icon_top.svg"
            /></el-button>
            <el-button
              v-show="!flag"
              class="click"
              @click="onClick(1)"
              type="text"
              >查看更多 <img src="@/assets/img/arrow/icon_button.svg"
            /></el-button>
      </div> -->
    <equity-structure ref="EquityStructure"></equity-structure>
    <vdialog v-bind:dialogData="comData" ref="dialogConfirm"></vdialog>
    <down-dialog ref="DownDialog"></down-dialog>
  </div>
</template>
<script>
// import { qcc_CompanyInfo } from '@/api/companyDetail.js';
import * as sesstion from "../../store/localStorage";

import { causeRport } from "@/api";
import qs from "qs";
let loadingFlag = [];
export default {
  props: {
    generalEvaluation: String,
    SWAI: String,
    CSRC: String,
    TJ: String,
    setCompanyUrl: Function,
  },
  components: {
    EquityStructure: require("./EquityStructure.vue").default,
    vdialog: require("../dialog/dialog").default,
    DownDialog: require("./DownDialog").default,
  },
  data() {
    return {
      loadings: false,
      flag: false,
      SWAI_code: [],
      CSRC_code: [],
      TJ_code: [],
      loading0: false,
      loading2: false,
      companyInfo: sesstion.getLocalData("company"),
      years: "",
      // qcc_CompanyInfo_data: {},
      comData: {
        iconType: "vip",
        rightText: "好的",
        lefText: "不了,谢谢",
        nextRouter: "/BuyService", //路由跳转到升级VIp的路由
        vipTips: "true",
      },
      company_type: sesstion.getLocalData("company").company_type,
      cause_industry_code: {
        3: ["xx", "学校"],
        4: ["yy", "医院"],
        5: ["qt", "其他"],
      },
    };
  },
  created() {
    // console.log(this.generalEvaluation)
    this.$VM.CompanyHeader = this;
    window.CompanyHeader = this;
  },
  mounted() {
    this.seave();
    // this.companyInfo_data();
  },
  methods: {
    onClick(value) {
      if (value == 0) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    // companyInfo_data() {
    //   setTimeout(() => {
    //     this.qcc_CompanyInfo_data = sesstion.getLocalData('company_name')
    //   }, 500);

    // },

    seave() {
      setTimeout(() => {
        this.CSRC_code = this.getLastLevelCategory(
          sesstion.getLocalData("industryData"),
          "tjResult"
        ).industry_name;
        this.SWAI_code = this.getLastLevelCategory(
          sesstion.getLocalData("industryData"),
          "swResult"
        ).industry_name;
        this.TJ_code = this.getLastLevelCategory(
          sesstion.getLocalData("industryData"),
          "gbResult"
        ).industry_name;
      }, 500);
    },
    getLastLevelCategory(data, type) {
      var keys = [];
      const categorysObj = data[type];
      let name = "";
      const types = {
        Result: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
        swResult: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
        gbResult: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
        tjResult: [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ],
      };

      types[type].some((industry_level) => {
        if (!categorysObj[industry_level]) return false;
        name += categorysObj[industry_level].industry_name + " /";
        keys.push(industry_level);
      });

      if (name[name.length - 1] == "/") name = name.substr(0, name.length - 2);
      return {
        industry_name: name,
      };
    },
    smallDownWord() {
      this.$router.push;
    },
    // check(data) {
    //   if (data) {
    //     this.$refs.EquityStructure.visible = true;
    //     this.$nextTick(() => {
    //       this.$refs.EquityStructure.showSeeksGraph(data);
    //     });
    //   } else {
    //     this.$message.error('未找到当前企业股权架构信息');
    //   }
    // },
    xlsxDown() {
      var company = sesstion.getLocalData("company");
      company = { ...company, ...(company.companyInfo || {}) };
      var data = null;

      if (this.$route.name == "smallCompany") {
        const PublicSearch = this.$VM.PublicSearch;
        data = {
          industry_code: company.SWAI_code,
          stock_code: "",
          report_year: PublicSearch.selectedYear,
          report_time: PublicSearch.report_time,
          report_type: PublicSearch.report_type,
          company_code: company.company_code,
          name: company.S_or_B_name,
        };
      } else {
        const PublicSearch = this.$VM.PublicSearch;
        var data = {
          ...this.$VM.PublicSearch.form_data,
          industry_code:
            PublicSearch.$refs[
              `selectTree${PublicSearch.category}`
            ].get_industry_code(),
          company_code: company.company_code || "",
          name: company.S_or_B_name,
        };
        data.report_year = data.year;
      }

      var token = sesstion.getLocalData("userData").auth_token;
      var url = `${window.BASE_URL}/report/debug/ana-dl/?` + qs.stringify(data);
      setTimeout(() => {
        this.loadings = false;
      }, 5000);
      let that = this;
      const x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.setRequestHeader("Authorization", "Bearer " + token);
      x.responseType = "blob";
      x.onload = function () {
        if (x.status == 200) {
          const url = window.URL.createObjectURL(x.response);
          const a = document.createElement("a");
          a.href = url;
          a.download = data.industry_code + ".xlsx";
          a.click();
        } else {
          that.$message.error("获取数据失败，暂无法分析！");
        }
      };

      x.send();
    },

    // qcc_CompanyInfo() {
    //   // console.log(this.companyInfo.name);

    //   qcc_CompanyInfo({ company_name: this.companyInfo.name }).then(
    //     ({ data }) => {
    //       if(data){
    //         this.qcc_CompanyInfo_data = data;
    //       }
    //       if(this.qcc_CompanyInfo_data.company_logo_url==null){
    //         this.qcc_CompanyInfo_data.company_logo_url='/img/wodeqiye1.svg'
    //       }
    //     }
    //   );
    // },
    async downWord(down) {
      if (this.$VM.PublicSearch.availableYears.length == 0)
        return this.$message.error("无可分析年份,无法下载");

      // const res = await checkpoint({
      //   company_code: '63e992cc-abf1-11e8-86fc-1c83410ad7e3',
      //   menu_id: this.$route.meta.menu_id,
      //   parsing_time: '2020',
      //   industry_type: '房地产开发业',
      //   industry_number: 'tj0701',
      //   industry_range: 0,
      //   report_time: '01',
      //   report_type: '00',
      // });

      // if (res.data.Status != 1) {
      //   this.$refs.dialogConfirm.showDialog();
      //   return false;
      // }

      const { href } = this.$router.resolve({
        name: `downWord`,
      });
      // 小薇企业下载
      // if (this.$route.name == 'smallCompany') {
      //   this.$VM.smallCompany.exportFDF();
      //   return false;
      // }
      let company = sesstion.getLocalData("company");
      // if (company.company_type >= 3) {
      //   const { href } = this.$router.resolve({
      //     name: `downWord`,
      //     query: {
      //       checkboxGroup: ["事业单位信贷报告"],
      //       status: 1,
      //     },
      //   });
      //   let form_data = this.$VM.PublicSearch.form_data
      //   causeRport({
      //     company_type: company.company_type,
      //     report_year: form_data.year,
      //     report_time: form_data.report_time,
      //     report_type: form_data.report_type,
      //     company_code: company.company_code,
      //   }).then(({ data }) => {
      //     if (data.Status != "1")
      //       return this.$message.error(logActivityRes.Message);
      //     window.file_type = down;
      //     sessionStorage.downWord = JSON.stringify({
      //       param: {
      //         company_type: company.company_type,
      //         report_year: form_data.year,
      //         report_time: form_data.report_time,
      //         report_type: form_data.report_type,
      //         company_code: company.company_code,
      //       },
      //       ...data,
      //       company_name: company.name,
      //       year: form_data.year,
      //       file_type: down,
      //     });
      //     let iframe = "";
      //     loadingFlag = [];
      //     ["事业单位信贷报告"].map((name) => {
      //       // console.log(name);
      //       const { href } = this.$router.resolve({
      //         name: `downWord`,
      //         query: {
      //           checkboxGroup: [name],
      //           status: down == 'pdf' ? 0 : 2,
      //         },
      //       });

      //       iframe += `<iframe style="position: fixed;left: 1px" class="iframe"  src="${href}"></iframe>`;
      //       loadingFlag.push({ name: name, status: true });
      //     });

      //     $("body").append(iframe);
      //     if (down == 'pdf') {
      //       this.loading0 = true;
      //     } else {
      //       this.loading2 = true;
      //     }
      //     // window.open(href);
      //   })
      //   return false;
      // }

      this.$refs.DownDialog.dialogVisible = true;
    },
    closeLoading(name) {
      for (let i = 0; i < loadingFlag.length; i++) {
        const item = loadingFlag[i];
        if (item.name == name) item.status = false;
      }
      const statusBox = loadingFlag.map((item) => item.status);
      if (statusBox.includes(true)) return false;
      loadingFlag = [];
      this.loading0 = false;
      this.loading2 = false;
    },
  },
  watch: {
    // companyInfo() {
    //   // this.qcc_CompanyInfo();
    //   this.qcc_CompanyInfo_data=sesstion.getLocalData('company_name')
    // },
  },
};
</script>
<style lang="less" scoped>
.pointer {
  cursor: pointer;
}

#company_header {
  height: 104px;
  background: #fff;
  margin-top: 0;

  .btn {
    width: 100%;
    margin: 0 auto;
  }

  * {
    line-height: 1;
  }

  > .containers {
    background: url("~@/assets/img/head/bg5-2.png") no-repeat top right;
    // background: url("~@/assets/img/head/bg5-1.png")no-repeat top left ;
    // padding-left: 30px;
    padding: 10px;
    // padding-top: 30px;
    // padding-right: 30px;
    position: relative;

    .boxtitle {
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
        // border-radius: 50%;
        // margin-right: 45px;
        border: 0;
      }

      img:not([src]) {
        opacity: 0;
      }

      .company_name {
        display: flex;
        align-items: center;
        margin-top: 15px;

        span:nth-child(1) {
          font-size: 22px;
          color: #333333;
        }

        span:nth-child(2) {
          background: rgba(25, 137, 250, 0.21);
          border: 1px solid #1989fa;
          border-radius: 2px;
          display: inline-block;
          // width: 43px;
          padding: 0 10px;
          line-height: 20px;
          height: 20px;
          margin-left: 12px;
          font-size: 12px;
          color: #1989fa;
          letter-spacing: 0;
          text-align: center;
        }
      }
    }

    .contentbox {
      display: flex;
      padding-left: 10px;
      margin-top: 20px;

      .left {
        flex: 1;
        display: flex;

        > .left_content {
          align-items: center;
        }

        > .left_content {
          padding-left: 10px;
          flex: 1;
          background: #fff;
        }
      }

      > .right {
        flex: 1;

        // padding-left: 75px;
        .item:last-child {
          span:nth-child(1) {
            width: 82px;
            float: left;
            // display: block;
          }
        }
      }
    }
  }

  .item {
    margin-top: 10px;
    font-size: 16px;
    color: #666666;

    span:nth-child(1) {
      color: #333;
    }
  }

  .green {
    color: #1989fa;
  }

  .black {
    color: #333333;
  }
}
</style>
