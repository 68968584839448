<template>
  <el-dialog title="股权架构" :visible.sync="visible" :fullscreen="true">
    <div style="height: calc(100vh - 100px)">
      <SeeksRelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
        :on-line-click="onLineClick"
      />
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      visible: false,

      currentCase: "双向树",
      isShowCodePanel: false,
      allowShowMiniToolBar: false,
      graphOptions: {
        // backgrounImage: "http://ai-mark.cn/images/ai-mark-desc.png",
        backgrounImageNoRepeat: false,
        layouts: [
          {
            label: "中心",
            layoutName: "tree",
            layoutClassName: "seeks-layout-center",
            defaultJunctionPoint: "border",
            defaultNodeShape: 0,
            defaultLineShape: 1,
            from: "left",
            max_per_width: "300",
            min_per_height: "40",
          },
        ],
        defaultLineMarker: {
          markerWidth: 12,
          markerHeight: 12,
          refX: 6,
          refY: 6,
          data: "M2,2 L10,6 L2,10 L6,6 L2,2",
        },
        defaultNodeShape: 1,
        defaultNodeWidth: "200",
        defaultLineShape: 4,
        defaultJunctionPoint: "lr",
        defaultNodeBorderWidth: 0,
        defaultLineColor: "#1989fa",
        defaultNodeColor: "#1989fa",
      },
      __graph_json_data: null,
    };
  },
  mounted() {},
  methods: {
    showSeeksGraph(query) {
      if (this.__graph_json_data) return false;
      this.__graph_json_data = query;
      // console.log(query);
      var __graph_json_data = query;
      this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, (seeksRGGraph) => {
        // 这些写上当图谱初始化完成后需要执行的代码
      });
    },
    onNodeClick(nodeObject, $event) {
      // console.log("onNodeClick:", nodeObject);
    },
    onLineClick(lineObject, $event) {
      // console.log("onLineClick:", lineObject);
    },
  },
};
</script>
