<template>
  <div class="row justify-content-between color-5d fz-14">
    <div class="row">
      <div>
        <div
          style="display: flex; align-items: center; font-size: 14px !important"
        >
          <span v-show="category == 'gb'">国标行业选择：</span>
          <span v-show="category == 'sw'">申万行业选择：</span>
          <!-- <RadioGroup v-model="category" style="margin-top:5px">
            <Radio label="gb">国标</Radio>
             <Radio label="tj">统计局</Radio>
            <Radio label="sw">申万</Radio>
          </RadioGroup> -->
          <!-- 国标 -->
          <div v-show="category == 'gb'">
            <selectTree
              :search="search"
              ref="selectTreegb"
              isName="gb"
              :isGb="true"
            />
          </div>
          <!-- 统计局 -->
          <div v-show="category == 'tj'">
            <selectTree :search="search" ref="selectTreetj" isName="tj" />
          </div>
          <!-- 申万 -->

          <div v-show="category == 'sw'">
            <selectTree ref="selectTreesw" isName="sw" :isSw="true" />
          </div>
        </div>
      </div>
      <div class="mr-3" style="margin-left: 30px; color: #333">
        <span>年份：</span>
        <el-select
          size="small"
          style="width: 120px"
          v-model="form_data.year"
          id="endYear"
          @visible-change="popoverValue = false"
        >
          <el-option-group label="截至年份">
            <el-option
              v-for="year in sortYear"
              :key="year"
              :value="year"
              :label="year"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>

      <div class="mr-3 ml-lg-2">
        <span>报表期间：</span>
        <el-select
          size="small"
          style="width: 120px"
          v-model="form_data.report_time"
          @visible-change="popoverValue = false"
        >
          <el-option-group label="报告日期">
            <el-option
              v-for="(item, report_time) in reasonReport"
              :key="report_time"
              :value="report_time"
              :label="reasonReportMapping[report_time]"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="mr-3 ml-lg-2">
        <span>报表种类：</span>
        <el-select
          size="small"
          style="width: 120px"
          v-model="form_data.report_type"
          class="br_4 cursor_pionter"
          id="report_type"
          @visible-change="popoverValue = false"
        >
          <el-option-group label="报告类型">
            <el-option
              v-for="report_type in report_type_arr"
              :key="report_type"
              :value="report_type"
              class="cursor_pionter"
              :label="report_type_mapping[report_type]"
            ></el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="mr-3 ml-lg-2">
        <el-tooltip
          class="item"
          effect="dark"
          content="同一个公司在不同字段间切换时，系统会扣减一次查询次数"
          placement="bottom-start"
        >
          <a class="btn_blue_middle" size="small" @click="search"> 点击分析 </a>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
let vm = null;
import * as sesstion from "../../store/localStorage";

export default {
  //   name: "publicSearch",
  dec: "公共的搜索区域",

  components: {
    selectTree: require("@/components/selectTree/selectTree.vue").default,
  },

  data() {
    return {
      popoverValue: false,
      company_code: "",
      availableYears: [],
      reasonReport: [],
      reasonReportMapping: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },

      category:
        sesstion.getLocalData("company").company_type == 0 ? "gb" : "sw",
      report_type_mapping: {
        "00": "合并报表",
        "01": "本部报表",
      },
      report_type_arr: [],
      form_data: {
        year: "",
        report_type: "",
        report_time: "",
        industry_code: "",
      },
      UseDefualt: false,
      company_type: sesstion.getLocalData("company").company_type,
      options: {
        3: ["xx", "学校"],
        4: ["yy", "医院"],
        5: ["qt", "其他"],
      },
    };
  },
  created() {
    this.$VM.PublicSearch = this;
    vm = this;
    if (this.$store.state.isLogin.loginStore != null) {
      if (this.$store.state.isLogin.loginStore.user_role == 1) {
        this.isVIP = false;
      } else if (this.$store.state.isLogin.loginStore.user_role == 2) {
        this.isVIP = true;
      }
    }
    if (this.company_type >= "3") this.search();

    if (this.$route.path == "/CompanyDetail/FinancialReport") this.search();
  },
  methods: {
    search() {
      var company = sesstion.getLocalData("company");
      company = { ...company, ...(company.companyInfo || {}) };
      this.$nextTick(() => {
        setTimeout(() => {
          let data = {
            ...this.form_data,
            industry_code:
              company.company_type >= "3"
                ? this.options[company.company_type][0]
                : this.$refs[`selectTree${this.category}`].get_industry_code(),
            industry_name:
              company.company_type >= "3"
                ? this.options[company.company_type][1]
                : this.$refs[`selectTree${this.category}`].get_f_title(),
            company_code: company.company_code,
            year: this.form_data.year || "",
            name: company.S_or_B_name || company.name,
            stock_code: company.S_or_B_code || "",
            report_year: this.form_data.year || "",
          };
          if (data.industry_code == "" || data.industry_name == "")
            return this.search();
          if (!data.year) return false;

          this.$VM[this.$route.name].search &&
            this.$VM[this.$route.name].search(data);
          this.$store.commit(
            "setYears",
            `(${data.year}${this.reasonReportMapping[data.report_time]})`
          );
          sesstion.setLocalData("companyData", data);
        }, 500);
      });
    },
    wath_trigger(wath_trigger_name) {
      this[wath_trigger_name] && this[wath_trigger_name]();
      return false;
    },
    selectedYear_change() {
      // 设置有数据的报告日期

      this.reasonReport = this.date_tree[this.form_data.year];
      for (const key in this.reasonReport) {
        if (this.reasonReport.hasOwnProperty(key)) {
          this.form_data.report_time = this.UseDefualt
            ? this.form_data.report_time
            : key;
          if (this.reasonReport[key]) {
            this.report_time_change();
          } else {
            this.report_type_arr = [];
            this.form_data.report_type = "";
          }

          break;
        }
      }
    },

    report_time_change() {
      // 设置有数据的报告类型
      this.report_type_arr =
        this.date_tree[this.form_data.year][this.form_data.report_time];
      this.form_data.report_type = this.UseDefualt
        ? this.form_data.report_type
        : this.date_tree[this.form_data.year][this.form_data.report_time][0];
      setTimeout(() => {
        this.UseDefualt = false;
      }, 3000);
    },
  },
  computed: {
    sortYear() {
      return (this.availableYears && this.availableYears.reverse()) || [];
    },
    smallOrcause(changeName) {
      if (
        this.$route.name == "smallCompany" ||
        "causeCompany" == this.$route.name
      )
        return true;
      return false;
    },
  },
  watch: {
    "form_data.year": function () {
      this.wath_trigger("selectedYear_change");
    },
    "form_data.report_time": function () {
      if (this.UseDefualt) {
      } else {
        this.wath_trigger("report_time_change");
      }
    },
    category() {
      this.search();
    },
    company_type() {
      this.search();
    },
  },
};
</script>
<style lang="less" scoped>
.btn_blue_middle {
  background-color: #3a85fd;
  width: 100px;
  height: 32px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  margin-left: 20px;
  display: block;
  text-align: center;
  padding: 5px;
}
.justify-content-between {
  justify-content: space-between;
}

.el-popover {
  min-width: 100px !important;
}

.mb_fenxi {
  padding: 5px 10px !important;
  height: auto;
  width: auto;
}

@media (max-width: 460px) {
  .mb_fenxi {
    padding: 3px 15px !important;
    height: auto;
    width: auto;
    font-size: 12px;
    margin-top: 6px;
  }
}
</style>
